<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style>

  @font-face {
      font-family: "Urbanist";
      src: url("@/assets/urbanist.ttf")
  }
  @font-face {
      font-family: "Zilla";
      src: url("@/assets/zilla.ttf")
  }
  .v-main {
    background: linear-gradient(0deg, rgba(159,59,62,1) 0%, rgba(191,91,94,1) 100%);
  }
  html ::-webkit-scrollbar  { 
      display:none;
  }
  html {
    font-family: "Urbanist" !important;
    font-weight: 600;
  }
  .v-container {
    max-width: 1200px !important;
  }

  .button-light {
    background: linear-gradient(90deg, #82F266 0%, #62D246 100%);
    border-radius: 12px;
    color: #113764;
    padding: 7px;
    font-weight: 700;
    box-shadow: 3px 3px 5px #00000055;
    user-select: none;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
  }
  .button-dark {
    background: linear-gradient(90deg, #F64F54 0%, #D10006 100%);
    border-radius: 12px;
    color: #ffffff;
    padding: 7px;
    font-weight: 700;
    box-shadow: 3px 3px 5px #00000055;
    user-select: none;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    text-decoration: none !important;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
}


.v-messages__message{
  color: white !important;
}
</style>
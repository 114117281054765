module.exports = {
    
    GET: async (route, data = {}) => {

        let query = `${process.env.VUE_APP_API_HOST}/${route}${ Object.keys(data).length > 0 ? (`?${ Object.keys(data).map(k => k + '=' + data[k]).join('&') }`) : '' }`;
        let result = await fetch(query);
        if (result.ok) {
            result = await result.json();
            if (result.error) alert(route + ': ' + result.info);
            return result;
        } 
        else return false;

    },

    POST: async (route, data = {}) => {
        
        let result = await fetch(`${process.env.VUE_APP_API_HOST}/${route}`, {
            method: "POST", 
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
        if (result.ok) {
            result = await result.json();
            if (result.error) alert(route + ': ' + result.info);
            return result;
        }
        else return false;

    }

}
<template>
	<div class="header">
		<a href="/"><img class="logo" :src="require(`@/assets/logo.png`)" style="height: 55px; margin-bottom: -10px; margin-left: 20px"/></a>
		<div style="margin-left: 10px">JetBacker v1.0</div>
		<div style="flex-grow: 1"></div>
		<router-link style="text-decoration: none; color: inherit;" to="/download">
			<div v-if="buttons != false" class="buttons button-light d-flex flex-column" style="width: 170px; margin-right: 10px">
				<div>СКАЧАТЬ ДЕМО</div>
				<div style="font-weight: 300; font-size: 10px; margin-top: -3px">БЕСПЛАТНО 14 ДНЕЙ</div>
			</div>
		</router-link>
			<router-link style="text-decoration: none; color: inherit;" to="/buy">
				<div v-if="buttons != false" class="buttons button-dark" style="width: 170px;  margin-right: 10px">
					<div>КУПИТЬ</div>
					<div style="font-weight: 300; font-size: 10px; margin-top: -3px">ПОЛНУЮ ВЕРСИЮ</div>
				</div>
		</router-link>
	</div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  props: ["buttons"]
}
</script>

<style scoped>

	@media screen and (min-width: 0px) and (max-width: 1100px) {
		.buttons {
			display: none !important;
		}
		.logo {
			margin-left: 10px !important;
		}
	}

	@media screen and (min-width: 1100px) {
		.buttons {
			display: block !important;
		}
	}

	.header {
		position: sticky;
		top: 0px;
		height: 70px;
		background-color: #b04d50;
		display: flex;
		align-items: center;
		color: #ffffff;
	}

</style>
<template>
	<div class="horizontal d-flex flex-row align-center header">
		<div class="text" style="margin-left: 20px">© JetBacker 2024</div>
		<div class="text" style="margin-left: 60px" v-if="false">Политика конфиденциальности</div>
		<div style="flex-grow: 1"></div>
		<div class="d-flex flex-column">
			<div class="text" style="margin-right: 20px; text-align: right;">E-Mail: <a href="mailto:info@jb-pro.ru">info@jb-pro.ru</a></div>
			<div class="text" style="margin-right: 20px; text-align: end; width: 200px;">ИНН 370243059418</div>
		</div>
	</div>
	<div class="vertical d-flex flex-row align-center header">
		<div class="d-flex flex-column justify-center header">
			<div class="text" style="margin-left: 20px">© JetBacker 2024</div>
			<div class="text" style="margin-left: 20px" v-if="false">Политика конфиденциальности</div>
		</div>
		<div style="flex-grow: 1"></div>
		<div class="d-flex flex-column">
			<div class="text" style="margin-right: 20px; text-align: end; width: 200px;">E-Mail: <a href="mailto:info@jb-pro.ru">info@jb-pro.ru</a></div>
			<div class="text" style="margin-right: 20px; text-align: end; width: 200px;">ИНН 370243059418</div>
		</div>
	</div>
</template>

<style scoped>

a {
	color: inherit;
	text-decoration: none;
}

	.header {
		height: 70px;
		width: 100%;
		background-color: #8F2B2E;
		bottom: 0;
	}

	.text {
		opacity: 50%;
	}

	@media screen and (min-width: 0px) and (max-width: 1100px) {
      .horizontal {
          display: none !important;
      }
      .vertical {
          display: flex !important;
      }

	  .header {
		font-size: 12px;
	  }
  }

  @media screen and (min-width: 1100px) {
      .horizontal {
          display: flex !important;
      }
      .vertical {
          display: none !important;
      }

	  .header {
		font-size: 15px;
		position: fixed;
	  }
  }

</style>
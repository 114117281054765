<template>
  <div class="home">
     <TopBar/>

    <v-container>
      <div class="horizontal d-flex flex-row" style="margin-top: 40px;">
        <div class="d-flex flex-column align-start">
          <img :src="require(`@/assets/logo-word.png`)" style="height: 55px; object-fit: contain;"/>
          <div class="subtitle">🎤 Незаменимый помощник музыкантам для работы на сцене</div>
          <div class="hr"/>
          <div class="hr"/>
          <div class="hr"/>
          <div class="hr"/>
          <div class="hr"/>
          <div class="adv">
            <ui>
            <li>🎙 Удобная и полноценная <span class="selected">работа с плейбэками на сцене</span></li>
            <div class="hr"/>
            <li>🖥 Поддержка нескольких экранов для автоматического <span class="selected">вывода футажей и плейлистов</span></li>
            <div class="hr"/>
            <li>📋 Простой и интуитивный механизм работы с плейлистами <span class="selected">с синхронизацией</span> и <span class="selected">удаленным управлением системой</span></li>
            <div class="hr"/>
            <li>📱 Управление системой <span class="selected">с любых устройств</span>: клавиатура, педали, смартфон, midi-устройства. </li>
            <div class="hr"/>
            <li>MacOS, IOS</li>
          </ui>
          </div>
        </div>

        <div style="margin-right: -100px;">
          <img :src="require(`@/assets/screen.png`)" style="height: 80vh;  object-fit: contain;"/>
        </div>
      </div>

      <div class="vertical d-flex flex-column align-center" style="margin-top: 40px; padding-bottom: 50px">
        <img :src="require(`@/assets/logo-word.png`)" style="height: 55px; object-fit: contain;"/>
        <div class="subtitle" style="text-align: center;">🎹🎤 Незаменимый помощник музыкантам для работы на сцене</div>

        <div class="d-flex flex-row align-center justify-center" style="margin-top: 20px; margin-bottom: 20px; flex-wrap: wrap; gap: 10px">
          <router-link style="text-decoration: none; color: inherit;" to="/download">
              <div class="button-light d-flex flex-column" style="width: 170px;">
              <div>СКАЧАТЬ ДЕМО</div>
              <div style="font-weight: 300; font-size: 10px; margin-top: -3px">БЕСПЛАТНО 14 ДНЕЙ</div>
            </div>
          </router-link>
          <router-link style="text-decoration: none; color: inherit;" to="/buy">
            <div class="button-dark" style="width: 170px;">
              <div>КУПИТЬ</div>
              <div style="font-weight: 300; font-size: 10px; margin-top: -3px">ПОЛНУЮ ВЕРСИЮ</div>
            </div>
          </router-link>
        </div>

        <div style="width:90%; margin-right: -50px;">
          <img :src="require(`@/assets/screen.png`)" style="width: 100%;  object-fit: contain; "/>
        </div>

        <div class="adv" style="max-width: 600px; margin-left: 10px; margin-right: 10px">
            <ui>
            <li>🎙 Удобная и полноценная <span class="selected">работа с плейбэками на сцене</span></li>
            <div class="hr"/>
            <li>🖥 Поддержка нескольких экранов для автоматического <span class="selected">вывода футажей и плейлистов</span></li>
            <div class="hr"/>
            <li>📋 Простой и интуитивный механизм работы с плейлистами <span class="selected">с синхронизацией</span> и <span class="selected">удаленным управлением системой</span></li>
            <div class="hr"/>
            <li>📱 Управление системой <span class="selected">с любых устройств</span>: клавиатура, педали, смартфон, midi-устройства. </li>
            <div class="hr"/>
            <li>MacOS, IOS</li>
          </ui>
          </div>

        </div>
    </v-container>

    <BottomBar/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue'
import BottomBar from '@/components/BottomBar.vue'

export default {
  name: 'Home',
  components: {
    TopBar,
    BottomBar
  },

	mounted() {

		

	}
}
</script>

<style scoped>

  @media screen and (min-width: 0px) and (max-width: 1100px) {
      .horizontal {
          display: none !important;
      }
      .vertical {
          display: flex !important;
      }
  }

  @media screen and (min-width: 1100px) {
      .horizontal {
          display: flex !important;
      }
      .vertical {
          display: none !important;
      }
  }

  .adv {
    white-space: pre-line;
    letter-spacing: -0.5px;
  }
  
  .home {
    font-family: "Roboto" !important;
    font-weight: 300;
    color: #fff;
  }
	
  .hr {
    height: 10px;
  }

  .subtitle {
      font-size: 14px;
      margin-left: 0px;
      font-family: "system-ui";
      font-weight: 600;
      /*color:#000000a3;*/
      color: #ffffffaa;
      /*text-decoration: underline;*/
  }

  .selected {
    color: #82F266;
    font-weight: 400;
  }

</style>
class YouKassa {

    //Оформить подписку
    async payInitial(email) {

        let resp = await HTTPS.GET('youkassa.getToken', { email });
        let token = resp.token;
        const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: token,
        
            customization: {
                modal: true,
                colors: {
                    control_primary: window.Telegram?.WebApp?.themeParams?.button_color,
                    background: window.Telegram?.WebApp?.themeParams?.bg_color,
                    control_primary_content: window.Telegram?.WebApp?.themeParams?.button_text_color,
                    text: window.Telegram?.WebApp?.themeParams?.text_color
                }
            },
						return_url: 'https://jetbacker.ru/paid/' + resp.id,
            error_callback: function(error) {
                
            }
        });
        
        await checkout.render();

        checkout.on('complete', e => {
            checkout.destroy();
        });

    }

}

module.exports = new YouKassa();